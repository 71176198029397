import React, { createContext, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { logout } from '../data/dataApi';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);
  const [showThanksPopup, setShowThanksPopup] = useState(false);
  const [loginPopupStatus, setLoginPopupStatus] = useState(false);
  const [mobMenu, setMobMenu] = useState(false);
  const [currentGame, setCurrentGame] = useState('');
  const [cookies, setCookie] = useCookies(['is18']);
  const [cookiePopup, setCookiePopup] = useCookies(['isPopup']);
  const [showCookiePopup, setShowCookiePopup] = useState(true);
  const [showAgePopup, setShowAgepopup] = useState(false);
  const [showRegisterBtn, setShowRegisterBtn] = useState(true);

  const toggleMobMenu = () => {
    setMobMenu(!mobMenu);
    console.clear();
  };

  const openRegisterPopup = (login = false) => {
    if (login) {
      setShowRegisterPopup(true);
      setLoginPopupStatus(true);
      setMobMenu(false);
      setCurrentGame('');
    } else {
      setShowRegisterPopup(true);
      setLoginPopupStatus(false);
      setMobMenu(false);
      setCurrentGame('');
    }
  };

  const closeRegisterPopup = () => {
    setShowRegisterPopup(false);
    setLoginPopupStatus(false);
  };

  const skipAndPlay = () =>{
      setShowRegisterPopup(false);
      setLoginPopupStatus(false);
      if (currentGame !== '') {
          navigate(`/play/${currentGame}/`);
      }else{
          navigate(`/all-games/`);
      }
  }

  const userRegistered = () => {
    closeRegisterPopup();
    setShowThanksPopup(true);
  };

  const userLogged = () => {
    closeRegisterPopup();
    window.location.reload();
  };

  const closeThanksPopup = () => {
    setShowThanksPopup(false);
    if (pathname === '/lp/' || pathname === '/lp') {
      window.location.href = "/lp/";
    } else if (pathname === '/top5/' || pathname === '/top5') {
      window.location.href = "/top5/";
    } else {
      navigate(0);
    }
  };

  const userLogout = async () => {
    const url = logout();
    await fetch(url)
      .then((response) => response.json())
      .then((result) => {
        navigate(0);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
    const getRandomGame = (arr) =>{
        function randomRange(min, max) {
            return (Math.random() * (max - min + 1)) + min
        }
        const game = parseInt(randomRange(0, arr.length - 1));
        const gameName = arr[game].name
        return gameName;
    }

  return (
    <UserContext.Provider
      value={{
        mobMenu,
        showRegisterPopup,
        setShowRegisterPopup,
        showThanksPopup,
        setShowThanksPopup,
        cookies,
        setCookie,
        showRegisterBtn,
        setShowRegisterBtn,
        openRegisterPopup,
        closeRegisterPopup,
        skipAndPlay,
        userRegistered,
        userLogged,
        closeThanksPopup,
        loginPopupStatus,
        userLogout,
        toggleMobMenu,
        showAgePopup,
        setShowAgepopup,
        cookiePopup,
        setCookiePopup,
        showCookiePopup,
        setShowCookiePopup,
        currentGame,
        setCurrentGame,
        getRandomGame
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
