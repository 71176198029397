import React, { useContext, useState } from 'react';
import { useUser } from '../../customHooks/userHooks';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import OwlCarousel from 'react-owl-carousel2';

import './FirstScreen.css';
import '../owl-carousel.css';

function FirstScreen() {
  let navigate = useNavigate();
  const { openRegisterPopup, setCurrentGame } = useContext(UserContext);
    const { showRegisterBtn } = useUser();


  const firstBannerGamesArr = ['lady-of-fortune', 'jade-magician', 'aztec', 'the-magic-cauldron', 'street-magic', 'super-flip'];
    const owlOptions = {
        margin: 15,
        autoplay: true,
        rewind: true,
        loop: true,
        nav: false,
        autoplaySpeed: true,
        autoplayTimeout: 5500,
        responsive: {
            300: {
                items: 2
            },
            560: {
                items:3
            },
            900: {
                items:3
            },
            1000: {
                items:4
            },
            1200: {
                items:6
            },
        }
    };

  return (
    <>
      <div className="first-screen-banner">
          <div className="main-container">
              <div className="banner-text-container">
                  {window.innerWidth <= 450 ? '' :
                      <div className="banner-image-block">
                          <img width='542' height='535' className="first-screen-image" src="/assets/images/home/first-screen-image.webp" alt="" />
                      </div>
                  }
                  <div className='banner-text-block'>
                <span className="title-mob">
                    <h1 className="banner-title title">Enter the magic world</h1>
                    {window.innerWidth <= 450 ? <img width='85' height='89' className="first-screen-image" src="/assets/images/home/book.webp" alt="" /> : null}
                </span>
                      <p className='banner-text'>Welcome to the world where all your dreams come true. It is a magic land of free social casinos where you can play without fear of losing money. All you have to do is to register, choose the game you want and start playing right now!</p>
                      <button className='banner-btn main-btn' onClick={() => {
                          if(showRegisterBtn){
                              openRegisterPopup(false);
                              setCurrentGame(`lady-of-fortune`);
                          }else{
                              navigate(`/play/lady-of-fortune/`);
                          }
                      }}>Start Now</button>
                  </div>
              </div>
          </div>
          <div className="first-screen-banner__games-wrap">
              <div className="first-screen-banner__games">
                  <OwlCarousel options={ owlOptions } >
                      {firstBannerGamesArr.map((gameName, i) =>
                          <div className="game-block" key={i}>
                              <div className='hover-img'>
                                  <button  onClick={() => {
                                      if(showRegisterBtn){
                                       openRegisterPopup(false);
                                       setCurrentGame(`${gameName}`);
                                      }else{
                                       navigate(`/play/${gameName}/`);
                                      }
                                  }}>Play now</button>
                              </div>
                              <img width='186' height='186' src={`/assets/images/home_games/${gameName}.webp`} className="first-screen-game" alt="banner" />
                          </div>
                      )}
                  </OwlCarousel>
              </div>
          </div>
        </div>
    </>
  );
}

export default FirstScreen;
