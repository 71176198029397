function checkAuth() {
  return '/api/user/is_auth.php';
}

function logout() {
  return '/api/user/logout.php';
}

function registerUser(id) {
  return `/api/user/${id}.php`;
}

export { checkAuth, registerUser, logout };
