import React, {useContext, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSClick } from '../customHooks/sclickHooks';

import Footer from '../components/Footer';

import './AllGames.css';
import {UserContext} from "../context/UserContext";
import {useUser} from "../customHooks/userHooks";

function AllGames() {
    let navigate = useNavigate();
    const { openRegisterPopup, setCurrentGame } = useContext(UserContext);
    const { showRegisterBtn } = useUser();
    const [contentHeight, setContentHeight] = useState();
    const { showSClickId, sClickIdFrameSrc, } = useSClick();

  useEffect(() => {
    document.title = 'Papislots - All Games';

    const contentBlock = document.querySelector('.top-page-body');
    setContentHeight(contentBlock.clientHeight - 400);
  }, [setContentHeight]);

    const allGamesArr = ['lady-of-fortune', 'jade-magician', 'aztec', 'the-magic-cauldron', 'super-flip', 'dragon-maiden', 'coils-of-cash', '5x-magic', 'street-magic', 'big-win-cat', 'fire-joker', 'wild-falls'];

  return (
    <>
        <div className="top-page-body allgames-page-body">
            {/*{window.innerWidth >= 1100 ? <img width='1440' height='auto' className="allgames-background" src="/assets/images/blue-bg.webp" alt="background" /> : null}*/}
            <div className="allgames-main-container main-container">
                <div className="allgames-text-block">
                    <h1 className="allgames-title title">All Games</h1>
                </div>
                <div className="allgames__games-wrap">
                    <div className="allgames__games">
                        {window.innerWidth <= 991 ?
                            allGamesArr.map((gameName, i) =>
                                <div className="game-block" key={i}>
                                    <div className='hover-img'>
                                        <button onClick={() => {
                                    if(showRegisterBtn){
                                        openRegisterPopup(false);
                                        setCurrentGame(`${gameName}`);
                                    }else{
                                        navigate(`/play/${gameName}/`);
                                    }
                                }}>Play now</button>
                                    </div>
                                    <img width='180' height='180' src={`/assets/images/all_games/${gameName}.webp`} className="allgames__game-image" alt="banner" />
                                </div>
                            ) :

                            <>
                                <div className="allgames__games-firstline">
                                    {allGamesArr.slice(0, 5).map((gameName, i) =>
                                        <div className="game-block" key={i}>
                                            <div className='hover-img'>
                                                <button onClick={() => {
                                    if(showRegisterBtn){
                                        openRegisterPopup(false);
                                        setCurrentGame(`${gameName}`);
                                    }else{
                                        navigate(`/play/${gameName}/`);
                                    }
                                }}>Play now</button>
                                            </div>
                                            <img width='180' height='180' src={`/assets/images/all_games/${gameName}.webp`} className="allgames__game-image" alt="banner" />
                                        </div>)
                                    }
                                </div>
                                <div className="allgames__games-secondline">
                                    {allGamesArr.slice(5, 9).map((gameName, i) =>
                                        <div className="game-block" key={i}>
                                            <div className='hover-img'>
                                                <button onClick={() => {
                                    if(showRegisterBtn){
                                        openRegisterPopup(false);
                                        setCurrentGame(`${gameName}`);
                                    }else{
                                        navigate(`/play/${gameName}/`);
                                    }
                                }}>Play now</button>
                                            </div>
                                            <img width='180' height='180' src={`/assets/images/all_games/${gameName}.webp`} className="allgames__game-image" alt="banner" />
                                        </div>)
                                    }
                                </div>
                                <div className="allgames__games-thirdline">
                                    {allGamesArr.slice(9).map((gameName, i) =>
                                        <div className="game-block" key={i}>
                                            <div className='hover-img'>
                                                <button onClick={() => {
                                    if(showRegisterBtn){
                                        openRegisterPopup(false);
                                        setCurrentGame(`${gameName}`);
                                    }else{
                                        navigate(`/play/${gameName}/`);
                                    }
                                }}>Play now</button>
                                            </div>
                                            <img width='180' height='180' src={`/assets/images/all_games/${gameName}.webp`} className="allgames__game-image" alt="banner" />
                                        </div>)
                                    }
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
            {showSClickId ? (
                <iframe title="sclickid" className="sclick-id-style" src={sClickIdFrameSrc}></iframe>
            ) : (null)}
        </div>
        <Footer />
    </>
);
}

export default AllGames;
