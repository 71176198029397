import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { useUser } from '../../customHooks/userHooks';

import './Guide.css';

function Guide() {
  let navigate = useNavigate();
  const { openRegisterPopup } = useContext(UserContext);
  const { canStartPlay } = useUser();

  const guideArr = [
    {image: 'choose-block',
    title: 'Choose game',
    description: 'Oh, what a difficult task is to choose a game to play. Especially if there are a bunch of them. To make it easier for you, we gathered votes from our users who attend our casino and composed a top 5 game list. So take a look and start playing!'
    },
    {image: 'have-block',
      title: 'Have fun',
      description: 'All you need now is to take your mobile device or a computer and start enjoying the game. Be sure that you will not lose any money here - everything is free of charge. It’s your place and time to have fun and thrill of casino game.'},
    {image: 'get-block',
      title: 'Get bonuses',
      description: 'While playing these games, you’ll receive virtual credits and leveling up in different ways. Registered visitors will also receive a newsletter about our updates and promotions to make your participation even more exciting.'}
  ]

  return (
    <div className="lp-guide-block">
      <div className="lp-guide-block-container main-container">
        <div className="lp-guide-text-block">
          <h1 className="lp-guide-title title">A short guide to our casino planet</h1>
        </div>
        <div className="lp-guide-steps__wrap">
          <div className="lp-guide-steps">
            {guideArr.map(({image, title, description}) =>(
                <div className="lp-guide-step" key={Math.random()} id={image}>
                  {window.innerWidth >= 664 ? <img width="276" height="307" src={`/assets/images/lp/${image}-img.png`} className="guide-step-icon" alt="Papislots"/> : null}
                  <div className="lp-guide-step__step-text">
                    {window.innerWidth >= 664 ? <h3>{title}</h3> : <span><h3>{title}</h3><img width="auto" height="auto" src={`/assets/images/lp/${image}-mob-img.png`} className="guide-step-icon" alt="Papislots"/> </span>}
                    <p>{description}</p>
                  </div>
                </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Guide;
