import React, {useEffect} from 'react';
import Footer from '../../components/Footer';

function Privacy() {

    useEffect(() => {
        document.title = `Papislots - Privacy Policy`;
    }, []);

    return (
        <>
            <div className="main-container">
                <div className="content-cover">
                    <h1 className='legal-title'>Privacy Policy</h1>
                    <p>Papislots Casino (hereinafter referred to as "we", "us", or "our") is dedicated to protecting the privacy of our players. This privacy policy (the "Policy") sets out how we collect, use, share and disclose personal information that we obtain from users visiting our Casino and interacting with its services.</p>
                    <p><b>Personal Data We Collect</b></p>
                    <p>We may gather personal information from users when they enter our Casino and use our website and services. </p>
                    <p>This personal information may cover:</p>
                        <ul>
                            <li>Name</li>
                            <li>Email address</li>
                            <li>IP address</li>
                            <li>Device information</li>
                        </ul>
                    <p><b>2. How We Use This Personal Information</b></p>
                        <ul>
                            <li>We may use the personal data we accumulate for the following aims:</li>
                            <li>To provide our services and to communicate with users about their accounts;</li>
                            <li>To adjust the user experience and to tailor our services to user preferences;</li>
                            <li>To process payments for virtual items and other purchases made through our website;</li>
                            <li>To analyze and improve our services, including researching and analyzing user behavior and trends;</li>
                            <li>To comply with legal responsibilities and to protect our rights and property.</li>
                        </ul>
                    <p><b>3. How We Share Personal Information</b></p>
                    <p>We may share and transmit the personal information we collect with third-party service operators helping us provide our services. We may also share these data with law enforcement bodies or other third parties as required by legislation or to protect our rights and property.</p>
                    <p><b>4. Security</b></p>
                    <p>We take proper steps to protect the personal information we collect from loss, unauthorized access, exposure, or destruction.</p>
                    <p><b>5. Juniors's Privacy</b></p>
                    <p>Our website and services are restricted from usage by children under 18. That's why you may be asked to give proof of your age by our Casino.</p>
                    <p><b>6. Changes to this Policy</b></p>
                    <p>We may update this Policy occasionally by posting a new version on our Casino website. Users are encouraged to review this page periodically to stay informed about our privacy practices amendments.</p>
                    <p>If you have any questions or concerns about our privacy practices or this Policy, please get in touch with us.
                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Privacy;