import React, {useContext, useState} from 'react';
import { useNavigate } from 'react-router-dom';

import './AboutBlock.css';
import {UserContext} from "../../context/UserContext";
import {useUser} from "../../customHooks/userHooks";
import { gamesUrlList } from '../../data/gamesUrlList';


function AboutBlock() {
  let navigate = useNavigate();
  const { openRegisterPopup, setCurrentGame, getRandomGame } = useContext(UserContext);
  // const { canStartPlay } = useUser();
  const { showRegisterBtn } = useUser();

    return (
    <div className="about-block">
      <div className='about-inner-block main-container'>
        <div className="about-block-text">
          <h2 className="banner-title title">Your new land of excitement</h2>
             <p>You arrived at the planet with a full range of social casino games created for your entertainment. We are fond of games ourselves, and we know how it is when you lose in slots. That’s why we created this place where you can be sure you’ll have lots of fun without risk. The laws of this planet are straightforward: you don’t pay us, and we don’t offer you any monetary prizes. Everything’s here about fun, joy, and enjoying your game. Now register, choose a game to play, and start your new day in the magic world!</p>
        </div>
      </div>
        {window.innerWidth <= 450 ? <img width='320' height='212' className="about-block-image" src="/assets/images/home/about-mob1.webp" alt="" /> : <img width='1728' height='1024' className="about-block-image" src="/assets/images/home/about.webp" alt="" />}
        {showRegisterBtn ? <button className='register-about-btn' onClick={() => {
              if(showRegisterBtn){
                openRegisterPopup(false);
                setCurrentGame('');
              }else{
                navigate(`/games/`);
              }
            }}>Register</button> :
            <button className='register-about-btn' onClick={() => { navigate(`/play/${getRandomGame(gamesUrlList)}/`); }}>Enjoy</button>}

      <button className='havefun-btn' onClick={() => {
                                      if(showRegisterBtn){
                                        openRegisterPopup(false);
                                        setCurrentGame('');
                                      }else{
                                        navigate(`/play/${getRandomGame(gamesUrlList)}/`);
                                      }
                                    }}>Have Fun</button>
    </div>
  );
}

export default AboutBlock;
