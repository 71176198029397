import React, { useContext } from 'react';
import { useUser } from '../../customHooks/userHooks';
import { useNavigate, Link } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { gamesUrlList } from '../../data/gamesUrlList';

import './Steps.css';

function Steps() {
  let navigate = useNavigate();
    const { openRegisterPopup, setCurrentGame, getRandomGame } = useContext(UserContext);
    const { showRegisterBtn } = useUser();

    const steps = [
        {imageUrl: 'step-1',
            name: 'Register',
            description: 'Please, register at Papislots to receive full access to our game and slots selection.',
        },
        {imageUrl: 'step-2',
            name: 'Start Playing',
            description: 'Have a look at our games and slots, our top lists of games and start playing right away!'
        },
        {imageUrl: 'step-3',
            name: 'Level Up',
            description: 'Enjoy a rewarding social casino experience at Papislots, earning virtual coins and leveling up.'
        }
    ];

  return (
    <>
      <div className="lp-steps-cover-wrap">
        <div className="main-container">
            <div className="lp-steps-cover">
                {steps.map(({imageUrl, name, description}) =>(
                    <div className="step-block" style={  window.innerWidth > 991 ? { backgroundImage: `url(/assets/images/lp/${imageUrl}.webp` } : { backgroundImage: `url(/assets/images/lp/${imageUrl}-mob.webp` } } key={Math.random()}>
                        <div className="step-text" id={imageUrl}>
                            <p>{description}</p>
                            <h3>{name}</h3>
                        </div>
                        { window.innerWidth < 991 ? <img width="111" height="109" src={`/assets/images/lp/${imageUrl}-img.webp`} className="step-icon" alt="Papislots"/> : null}
                    </div>
                ))}
            </div>
            <button className="lp-play-now" onClick={() => {
                if(showRegisterBtn){
                    openRegisterPopup(false);
                    setCurrentGame('');
                }else{
                    navigate(`/play/${getRandomGame(gamesUrlList)}/`);
                }
            }}>Play now</button>
        </div>
      </div>
    </>
  );
}

export default Steps;
