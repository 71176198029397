import React, { useState, useEffect } from 'react';
import Footer from '../components/Footer';
import FirstScreen from '../components/home_blocks/FirstScreen';
import AboutBlock from '../components/home_blocks/AboutBlock';
import TopGames from '../components/home_blocks/TopGames';

import './Home.css';

function Home() {
  const [bodyHeight, setBodyHeight] = useState();
  // const [theGame, setTheGame] = useState();

  useEffect(() => {
    document.title = 'Papislots - Home';
    document.querySelector("meta[name='description']").setAttribute('content', "Papislots is a cozy and safe place full of coolest games. Enjoy free-to-play social casino games with no real money prizes. Sign in today, spin the wheel and enjoy!");
    const contentBlock = document.querySelector('#games-carousel-cover');
    setBodyHeight(contentBlock.clientHeight - 400);
  }, []);

  return (
    <div className="home-page-cover">
      <FirstScreen />
      <AboutBlock />
      <TopGames />
      <div id="games-carousel-cover" style={{ minHeight: `${bodyHeight}px` }}></div>

      <Footer className={'home-footer'} />
    </div>
  );
}

export default Home;
