import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import Disclaimer from '../components/legal_blocks/Disclaimer';
import Privacy from '../components/legal_blocks/Privacy';
import Terms from '../components/legal_blocks/Terms';
import Cookies from '../components/legal_blocks/Cookies';

import './Legal.css';

function Legal() {
    const {pathname} = useLocation();
    const [element, setElement] = useState(<Privacy />);

    useEffect(() => {
        switch(pathname) {
            case '/disclaimer/':
                setElement(<Disclaimer />);
                break;
            case '/privacy/':
                setElement(<Privacy />);
                break;
            case '/terms/':
                setElement(<Terms />);
                break;
            case '/cookies/':
                setElement(<Cookies />);
                break;
        }
    }, [pathname]);

    return (
        <div className="text-page-body legal-page-body">
            {element}
        </div>
    );
}

export default Legal;