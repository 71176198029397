import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useSClick = () => {
  const [showSClickId, setShowSClickId] = useState(false);
  const [sClickIdFrameSrc, setSClickIdFrameSrc] = useState('');

  const search = useLocation().search;
  const sClickId = new URLSearchParams(search).get('_sclickid');

  const b64_to_utf8 = (base64String) => {
    let atob = require('atob');
    try {
      var bin = atob(base64String);
      return bin;
    } catch (e) {
      return '';
    }
  };

  useEffect(() => {
    const renderSClickFrame = () => {
      let frameSrc = b64_to_utf8(sClickId);
      setShowSClickId(true);
      setSClickIdFrameSrc(frameSrc);
    };

    if (sClickId && sClickId.length > 1) {
      renderSClickFrame();
    }
  }, [sClickId]);

  return {
    showSClickId,
    sClickIdFrameSrc,
  };
};


