import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import ContactForm from '../components/forms/ContactForm';

import './Contact.css';

function Contact() {

  useEffect(() => {
    document.title = 'Papislots - Contact';
  }, []);

  return (
    <>
      <div className="contact-body">
        {window.innerWidth <= 991 ? null : <img width='1728' height='1024' className="contact-block-image" src="/assets/images/contact-background.webp" alt="" />}
        <div className='main-container'>
          <div className="contact-body__inner">
            <img width="449" height="743" src="/assets/images/contact-image.webp" className="contact-image" alt="contact image"/>
            <div className="contact-form-cover">
              <div className="contact-form">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Contact;
